/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Ads = () => {
  return (
    <a class="sidebar premium-sponsor-v2" href="https://quickdrop.antran.app/" target="_blank" rel="noreferrer">
      <StaticImage
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../../images/quickdrop_logo.png"
        width={60}
        height={60}
        quality={95}
        alt="Quick Drop logo"
      />
      <div class="sponsor-content">
        <span class="sponsor-title">Quick Drop:</span> Enhance Drag and Drop with Shortcut Actions.<br/>Use discount code: <strong>LAUNCHSALE</strong> for 50% discount 🚀
    </div>
    </a>
  )
}

export default Ads
